import { Routes } from "@angular/router";

import { Permissions, Roles } from "@vre-wave/domain";
import {
    CorrectOfficeGuard,
    CorrectOrgGuard,
    LoginGuard,
    PermissionGuard
} from "@vre-wave/infrastructure";

export const APP_ROUTES: Routes = [
    {
        loadComponent: () => import("./login/login.component").then(c => c.LoginComponent),
        path: "login"
    },
    {
        canActivate: [LoginGuard, CorrectOrgGuard, CorrectOfficeGuard],
        canActivateChild: [PermissionGuard],
        data: {
            anyRole: true,
            permissions: [Permissions.ReportsRead],
            roles: [Roles.Webtop, Roles.Admin]
        },
        loadComponent: () => import("./reports/reports.component").then(r => r.ReportsComponent),
        path: "reports"
    },
    {
        canActivate: [LoginGuard, CorrectOrgGuard, CorrectOfficeGuard],
        canActivateChild: [PermissionGuard],
        data: {
            internalReport: true,
            permissions: [Permissions.ReportsRead],
            roles: [Roles.Webtop]
        },
        loadComponent: () => import("./reports/reports.component").then(r => r.ReportsComponent),
        path: "internal-reports"
    },
    {
        canActivate: [LoginGuard, CorrectOrgGuard, CorrectOfficeGuard],
        canActivateChild: [PermissionGuard],
        data: { Permissions: [Permissions.OrganizationRead] },
        loadChildren: () => import("./admin/admin.routing").then(m => m.ADMIN_ROUTES),
        path: "admin"
    },
    {
        path: "campaigns",
        redirectTo: "campaign-object"
    },
    {
        canActivate: [LoginGuard, CorrectOrgGuard, CorrectOfficeGuard],
        canActivateChild: [PermissionGuard],
        data: { permissions: [Permissions.CasesRead] },
        loadChildren: () =>
            import("./campaign-object/campaign-object.routing").then(m => m.CAMPAIGN_ROUTES),
        path: "campaign-object"
    },
    {
        canActivate: [LoginGuard, CorrectOrgGuard, CorrectOfficeGuard],
        canActivateChild: [PermissionGuard],
        data: { roles: [Roles.Webtop] },
        loadChildren: () => import("./economy/economy.routing").then(m => m.ECONOMY_ROUTES),
        path: "economy"
    },
    {
        loadComponent: () => import("./tos/tos.component").then(c => c.TosComponent),
        path: "terms"
    },
    {
        loadChildren: () => import("./public/public.routing").then(m => m.PUBLIC_ROUTES),
        path: "public"
    },
    {
        data: { error: 403 },
        loadComponent: () => import("./error/error.component").then(c => c.ErrorComponent),
        path: "403"
    },
    {
        data: { error: 404 },
        loadComponent: () => import("./error/error.component").then(c => c.ErrorComponent),
        path: "404"
    },
    {
        loadComponent: () =>
            import("./login/callback.component").then(c => c.SigninCallbackComponent),
        path: "oidc-callback"
    },

    { path: "", pathMatch: "full", redirectTo: "campaign-object/office/dashboard" },
    { path: ":lang", redirectTo: "" },
    { path: "**", redirectTo: "campaign-object/office/dashboard" }
];
