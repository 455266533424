import { OverlayContainer } from "@angular/cdk/overlay";
import { AsyncPipe, NgTemplateOutlet } from "@angular/common";
import { AfterViewInit, Component, Inject } from "@angular/core";
import { ReactiveFormsModule, UntypedFormControl } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatOptionModule } from "@angular/material/core";
import { MatIconRegistry } from "@angular/material/icon";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { DomSanitizer } from "@angular/platform-browser";
import { NavigationEnd, Router, RouterLink, RouterOutlet } from "@angular/router";
import { FlexLayoutModule } from "@ngbracket/ngx-layout";
import {
    L10N_CONFIG,
    L10N_LOCALE,
    L10nConfig,
    L10nLocale,
    L10nTranslateDirective
} from "angular-l10n";
import { filter, map } from "rxjs/operators";

import { IntercomDirective } from "@vre-wave/components";
import { AuthService, TitleService, TranslationService } from "@vre-wave/infrastructure";

import { LoadingBarComponent } from "./shared/loading-bar/loading-bar.component";
import { NavigationComponent } from "./shared/navigation/navigation.component";
import { TranslatePipe } from "./shared/pipes/translate.pipe";

@Component({
    imports: [
        NavigationComponent,
        LoadingBarComponent,
        NgTemplateOutlet,
        RouterLink,
        MatSelectModule,
        MatOptionModule,
        RouterOutlet,
        AsyncPipe,
        MatButtonModule,
        MatSlideToggleModule,
        IntercomDirective,
        TranslatePipe,
        FlexLayoutModule,
        L10nTranslateDirective,
        ReactiveFormsModule
    ],
    selector: "ws-wave",
    standalone: true,
    styleUrls: ["./app.component.scss"],
    templateUrl: "./app.component.html"
})
export class AppComponent implements AfterViewInit {
    versionNumber = "0.0";

    darkMode = new UntypedFormControl(false);

    get isloggingIn$() {
        return this.authService.loggedIn$.pipe(
            map(x => !x && !location.pathname.includes("/login"))
        );
    }

    isPublic = false;
    isErrorPage = false;

    constructor(
        @Inject(L10N_LOCALE) public locale: L10nLocale,
        @Inject(L10N_CONFIG) private config: L10nConfig,
        overlayContainer: OverlayContainer,
        private authService: AuthService,
        private translationService: TranslationService,
        router: Router,
        titleService: TitleService,
        matIconRegistry: MatIconRegistry,
        domSan: DomSanitizer
    ) {
        // Register custom svg-icons here
        matIconRegistry.addSvgIcon(
            "instagram-outline",
            domSan.bypassSecurityTrustResourceUrl("../../assets/icons/icons8-instagram.svg")
        );
        matIconRegistry.addSvgIcon(
            "instagram-svg",
            domSan.bypassSecurityTrustResourceUrl("../../assets/icons/instagram-icon.svg")
        );
        matIconRegistry.addSvgIcon(
            "facebook-svg",
            domSan.bypassSecurityTrustResourceUrl("../../assets/icons/facebook-icon.svg")
        );
        matIconRegistry.addSvgIcon(
            "google-svg",
            domSan.bypassSecurityTrustResourceUrl("../../assets/icons/google-icon.svg")
        );

        titleService.setTitle();
        router.events
            .pipe(
                filter(x => x instanceof NavigationEnd),
                map(x => x as NavigationEnd)
            )
            .subscribe(res => {
                this.isPublic =
                    res.urlAfterRedirects.includes("/public") || res.url.includes("/public");
                this.isErrorPage =
                    res.urlAfterRedirects.includes("404") ||
                    res.url.includes("404") ||
                    res.urlAfterRedirects.includes("403") ||
                    res.url.includes("403");
            });

        this.darkMode.valueChanges.subscribe(res => {
            if (res) {
                overlayContainer.getContainerElement()?.classList.add("dark-theme");
                document.getElementById("wave-app")?.classList.add("dark-theme");
                localStorage.setItem("theme", "dark-theme");
            } else {
                overlayContainer.getContainerElement()?.classList.remove("dark-theme");
                document.getElementById("wave-app")?.classList.remove("dark-theme");
                localStorage.setItem("theme", "light-theme");
            }
        });
    }

    setLang(lang: { value: "en" | "nb" | "sv" }) {
        const locale = this.config.schema.find(x => x.text === lang.value)?.locale;
        if (locale) this.translationService.setLang(locale);
    }

    ngAfterViewInit() {
        if (localStorage.getItem("theme") === "dark-theme") {
            this.darkMode.setValue(true);
        }
    }
}
