import { DestroyRef, Directive, inject, Renderer2, signal } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { EventType, Router } from "@angular/router";
import { Office, Organization, UserSettings } from "@vre-wave/domain";
import {
    AuthService,
    environment,
    OrganizationDataService,
    UserService
} from "@vre-wave/infrastructure";
import { User } from "oidc-client-ts";
import { filter, firstValueFrom, forkJoin, fromEvent, of } from "rxjs";

declare global {
    interface IntercomSettings {
        app_id: string;
        api_base: string;
        created_at?: number;
        email: string;
        phone: string;
        name: string;
        product: "Wave";
        company: {
            id: string;
            name: string;
            office_name: string;
            office_id: string;
        };
        hide_default_launcher?: boolean;
        user_hash: string;
    }

    interface Window {
        Intercom: {
            (
                func: "boot" | "reattach_activator" | "update",
                s?: IntercomSettings | Partial<IntercomSettings>
            ): void;
            q: unknown[];
            c: (args: unknown) => void;
        };
        intercomSettings: IntercomSettings;
    }
}

@Directive({
    selector: "[wsIntercom]",
    standalone: true
})
export class IntercomDirective {
    private renderer = inject(Renderer2);
    private orgService = inject(OrganizationDataService);
    private settings = inject(UserService);
    private auth = inject(AuthService);
    private router = inject(Router);
    private destryRef = inject(DestroyRef);
    private useIntercom = signal(false);
    initialInit = true;

    constructor() {
        this.settings.userSettings.then(res =>
            forkJoin([
                firstValueFrom(this.orgService.loadEntity$(res.activeOrganizationId)),
                res.mainOffice ? this.orgService.getOffice$(res.mainOffice) : of(undefined),
                this.auth.getUser(),
                this.settings.userSettings
            ]).subscribe(async res => await this.updateIntercom(res[2], res[0], res[3], res[1]))
        );

        fromEvent(window, "focus")
            .pipe(takeUntilDestroyed(this.destryRef))
            .subscribe(() => this.refreshIntercom());

        this.router.events
            .pipe(
                takeUntilDestroyed(this.destryRef),
                filter(x => x.type == EventType.NavigationEnd && !this.initialInit)
            )
            .subscribe(() => this.refreshIntercom());
    }

    private refreshIntercom() {
        if (this.useIntercom() && window.Intercom) window.Intercom("update");
    }

    private async updateIntercom(
        user: User,
        org: Organization,
        settings: UserSettings,
        office?: Office
    ) {
        this.useIntercom.set(org.organizationSettings.useIntercom);
        if (this.useIntercom()) {
            if (this.initialInit) this.initialize();

            window.Intercom(this.initialInit ? "boot" : "update", {
                api_base: "https://api-iam.eu.intercom.io",
                app_id: environment.intercomWorkspace,
                company: {
                    id: org.organizationNumber ?? "",
                    name: org.name ?? "",
                    office_id: office?.organizationNumber ?? "",
                    office_name: office?.companyName ?? office?.name ?? ""
                },
                email: settings.login as string,
                name: user.profile.name as string,
                phone: user.profile.phone_number as string,
                product: "Wave",
                user_hash: settings.userHmac
            });
            this.initialInit = false;
        }
    }

    initialize() {
        const w = window;
        const ic = w.Intercom;
        if (typeof ic === "function") {
            ic("reattach_activator");
            ic("update", w.intercomSettings);
        } else {
            const i = function (...args: unknown[]) {
                i.c(args);
            };
            i.q = [] as unknown[];
            i.c = function (args: unknown) {
                i.q.push(args);
            };
            w.Intercom = i;
        }

        const script = this.renderer.createElement("script");
        script.type = "text/javascript";
        script.async = true;
        script.src = "https://widget.intercom.io/widget/" + environment.intercomWorkspace;
        this.renderer.appendChild(document.body, script);
    }
}
