<div fxLayout="column" fxFill>
    @if (showMenu && !isLoggingIn && (hideNav$ | async) === false) {
        <mat-toolbar class="mat-elevation-z4" style="z-index: 2">
            <mat-toolbar-row>
                <a mat-icon-button aria-label="Sidenav toggle" data-cy="sidenav-toggle" (click)="toggleDrawer()">
                    <mat-icon aria-label="Sidenav toggle icon">menu</mat-icon>
                </a>
                <span fxFlex></span>
                @if (navigationHeader?.length && navigationHeader![0]) {
                    <h1 l10nTranslate>
                        @if (!isXs()) {
                            <span> {{ navigationHeader![0] }} </span>
                        }
                        @if (navigationHeader?.length !== 1 && !isXs()) {
                            <span> > </span>
                        }
                        {{ navigationHeader![1] | translate: locale.language }}
                    </h1>
                }
                <span fxFlex></span>
                <button #profileButton mat-icon-button data-cy="profile-btn" (click)="toggleProfileCard()">
                    <mat-icon>account_circle</mat-icon>
                </button>
            </mat-toolbar-row>
        </mat-toolbar>
    }
    <mat-sidenav-container fxFlex [ngClass]="{ 'nav-container-fix': isHandSet() === true }">
        <mat-sidenav
            #drawer
            [fixedInViewport]="isHandSet()"
            [attr.role]="isHandSet() ? 'dialog' : 'navigation'"
            [mode]="isHandSet() ? 'over' : 'side'"
            [opened]="isHandSet() === false && showMenu && !isLoggingIn && (hideNav$ | async) === false"
        >
            <div fxLayout="column" fxFill>
                <div class="new-campaign-btn-container">
                    <button
                        #menuTrigger="matMenuTrigger"
                        mat-raised-button
                        color="primary"
                        data-cy="new-campaign-btn"
                        [matMenuTriggerFor]="campaignMenu"
                        (click)="navigateToOfficeCampaign(menuTrigger)"
                    >
                        <mat-icon>add</mat-icon>
                        <span l10nTranslate>navigation.new_ad.create</span>
                    </button>
                    <mat-menu #campaignMenu="matMenu" xPosition="after">
                        <button
                            mat-menu-item
                            data-cy="new-office-campaign-btn"
                            (click)="navigate('./campaign-object/office/new')"
                        >
                            <mat-icon>apartment</mat-icon>
                            <span l10nTranslate>navigation.new_ad.office</span>
                        </button>
                        @if (lastCaseId) {
                            <button mat-menu-item data-cy="new-case-campaign-btn" (click)="navigateToNewCaseCampaign()">
                                <mat-icon>house</mat-icon>
                                <span l10nTranslate>navigation.new_ad.case</span>
                            </button>
                        }
                    </mat-menu>
                </div>
                <mat-nav-list [ngClass]="{ 'nav-list-fix': isHandSet() === true }">
                    @for (
                        navSection of navSections;
                        track navSection.section;
                        let i = $index;
                        let l = $last;
                        let f = $first
                    ) {
                        @if (navSection?.visible()) {
                            @if (!f && navSections[i - 1].visible()) {
                                <mat-divider class="sidenav-divider"></mat-divider>
                            }
                            @if (navSection.name) {
                                <div matSubheader l10nTranslate>{{ navSection.name }}</div>
                            }
                            @for (elem of navSection.elements; track elem.text) {
                                @if (elem?.visible()) {
                                    <a
                                        mat-list-item
                                        [activated]="elem?.active() ?? false"
                                        [attr.data-cy]="elem.text"
                                        (click)="navigate(elem.route)"
                                    >
                                        <mat-icon matListItemIcon>{{ elem.icon }}</mat-icon>
                                        <span matListItemTitle l10nTranslate>{{ elem.text }}</span>
                                    </a>
                                }
                            }
                        }
                    }
                </mat-nav-list>
                <span fxFlex></span>
                <div fxLayout fxLayoutAlign="center center" style="padding: 10px 0px">
                    <img
                        alt="Visma logo"
                        src="../../../assets/logo/visma-logo-dark.svg"
                        width="100"
                        [fxShow]="isDarkMode"
                    />
                    <img
                        alt="Visma logo"
                        src="../../../assets/logo/visma-logo.svg"
                        width="100"
                        [fxShow]="!isDarkMode"
                    />
                </div>
            </div>
        </mat-sidenav>
        <!-- RIGHT SIDE MENU DRAWER -->
        <mat-sidenav #rightSideNav position="end" mode="over" [style.width]="sideNavService.width">
            @if (sideNavService.sideNavPortal && rightSideNav.opened) {
                <div fxFill fxLayout="column" style="overflow-y: hidden">
                    @if (sideNavService.title) {
                        <h1 l10nTranslate style="padding: 5px 10px; margin-bottom: 0">{{ sideNavService.title }}</h1>
                    }
                    <div fxFlex style="overflow-y: auto; padding: 10px">
                        <ng-template [cdkPortalOutlet]="sideNavService.sideNavPortal"></ng-template>
                    </div>
                    <div fxLayout="row" fxLayoutGap="10px" style="padding: 10px">
                        @for (act of sideNavService.actions(); track act.text) {
                            @if (act.isPrimary) {
                                <button
                                    mat-flat-button
                                    [disabled]="act.disabled && act.disabled()"
                                    [color]="act.color"
                                    (click)="act.action()"
                                >
                                    <mat-icon [fxShow]="act.icon">{{ act.icon }}</mat-icon>
                                    <span l10nTranslate>{{ act.text }}</span>
                                </button>
                            } @else {
                                <button
                                    mat-stroked-button
                                    [disabled]="act.disabled && act.disabled()"
                                    [color]="act.color"
                                    (click)="act.action()"
                                >
                                    <mat-icon [fxShow]="act.icon">{{ act.icon }}</mat-icon>
                                    <span l10nTranslate>{{ act.text }}</span>
                                </button>
                            }
                        }
                    </div>
                </div>
            }
        </mat-sidenav>
        <mat-sidenav-content>
            <ng-content></ng-content>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>
@if (member) {
    <mat-card #profileCard class="profile-card" [fxShow]="showProfileCard()">
        <mat-card-header>
            <div class="member-img-wrapper" mat-card-avatar>
                @if (member.mainImage?.url) {
                    <img loading="lazy" alt="" [src]="member.mainImage?.url" (load)="cropBrokerImg($event)" />
                }
                @if (!member.mainImage?.url) {
                    <mat-icon mat-list-icon style="font-size: 40px; height: auto; width: auto">account_circle</mat-icon>
                }
            </div>
            @if (member) {
                <mat-card-title>{{ member.firstName }} {{ member.lastName }}</mat-card-title>
            }
            <mat-card-subtitle l10nTranslate>{{ memberRole }}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            @if (form) {
                <div fxLayout="column">
                    <button
                        mat-stroked-button
                        data-cy="member-edit-btn"
                        style="margin-bottom: 1.34375em"
                        (click)="openMemberEdit()"
                    >
                        <mat-icon>manage_accounts</mat-icon>
                        <span l10nTranslate>navigation.member_settings</span>
                    </button>
                    <mat-form-field style="margin-bottom: -1.34375em">
                        <mat-label l10nTranslate>organizations.office.selected_office</mat-label>
                        <input
                            #input
                            type="text"
                            matInput
                            data-cy="active-office-filter"
                            [matAutocomplete]="auto"
                            [formControl]="officeFilter"
                            (focus)="onOfficeFilterFocus()"
                            (blur)="resetOfficeFilter()"
                        />
                        <mat-autocomplete
                            #auto="matAutocomplete"
                            [displayWith]="officeNameFn"
                            (optionSelected)="selectOffice($event.option.value)"
                        >
                            @for (office of filteredOffices(); track office.id) {
                                <mat-option data-cy="active-office-option" [value]="office.id">
                                    {{ office.name }}
                                </mat-option>
                            }
                        </mat-autocomplete>
                    </mat-form-field>
                    <mat-checkbox
                        color="primary"
                        data-cy="main-office-checkbox"
                        l10nTranslate
                        style="margin-bottom: 1.34375em"
                        [formControl]="form.controls.isMainOffice"
                        >navigation.main_office
                    </mat-checkbox>
                    @if (organizations() && hasFullAccess()) {
                        <mat-form-field>
                            <mat-label l10nTranslate>navigation.admin.organization</mat-label>
                            <mat-select
                                data-cy="org-select"
                                [value]="activeOrgId"
                                (selectionChange)="changeActiveOrg($event)"
                            >
                                @for (org of organizations(); track org.id) {
                                    <mat-option data-cy="org-option" [value]="org.id">{{ org.name }}</mat-option>
                                }
                            </mat-select>
                        </mat-form-field>
                    }
                    <mat-form-field>
                        <mat-label l10nTranslate>navigation.language</mat-label>
                        <mat-select
                            data-cy="language-select"
                            [placeholder]="'select_lang' | translate: locale.language"
                            [value]="locale.language"
                            (selectionChange)="setLang($event.value)"
                        >
                            <mat-option data-cy="language-option-nb" value="nb">Norsk</mat-option>
                            <mat-option data-cy="language-option-sv" value="sv">Svenska</mat-option>
                            <mat-option data-cy="language-option-en" value="en">English</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div>
                        <mat-slide-toggle
                            color="primary"
                            data-cy="dark-mode-toggle"
                            l10nTranslate
                            style="margin-bottom: 1.34375em"
                            [formControl]="darkMode"
                            >dark
                        </mat-slide-toggle>
                    </div>
                    <button mat-stroked-button style="margin-bottom: 1.34375em" (click)="signOut()">
                        <mat-icon>logout</mat-icon>
                        <span l10nTranslate> navigation.log_out </span>
                    </button>
                    <div fxLayout="column" fxLayoutAlign="start center">
                        <a routerLink="/terms" l10nTranslate>tos.terms-link</a>
                        <a href="https://visma.no/eiendom/eiendomsmegling/">&copy; Visma Real Estate AS 2022</a>
                        <small>v.{{ versionNumber }}</small>
                    </div>
                </div>
            }
        </mat-card-content>
    </mat-card>
}
