import { OverlayContainer } from "@angular/cdk/overlay";
import { PortalModule } from "@angular/cdk/portal";
import { AsyncPipe, NgClass } from "@angular/common";
import {
    AfterViewInit,
    Component,
    computed,
    DestroyRef,
    effect,
    ElementRef,
    Inject,
    Input,
    signal,
    Signal,
    ViewChild
} from "@angular/core";
import { takeUntilDestroyed, toSignal } from "@angular/core/rxjs-interop";
import { FormControl, FormGroup } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatOptionModule } from "@angular/material/core";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule, MatMenuTrigger } from "@angular/material/menu";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenav, MatSidenavModule } from "@angular/material/sidenav";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatToolbarModule } from "@angular/material/toolbar";
import { NavigationEnd, NavigationExtras, Router, RouterLink } from "@angular/router";
import { ExtendedModule } from "@ngbracket/ngx-layout/extended";
import { L10N_CONFIG, L10nConfig, L10nLocale } from "angular-l10n";
import { combineLatest, EMPTY, fromEvent, of, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged, filter, map, switchMap } from "rxjs/operators";

import {
    AspectRatioEnum,
    getHighestRoleCodeFromList,
    Member,
    Office,
    Organization,
    Permissions,
    Roles
} from "@vre-wave/domain";
import {
    AuthService,
    BreakpointService,
    CaseDataService,
    environment,
    OfficeDataService,
    OrganizationDataService,
    ToastService,
    TranslationKeys,
    TranslationService,
    UserService,
    version
} from "@vre-wave/infrastructure";

import { WsCommonImports, WsFormsImports } from "../../ws-common";
import { ConfirmActionService } from "../confirm-popup/confirm-action.service";
import { AsControlPipe } from "../forms/as-group.pipe";
import { BreadCrumbHelper } from "../helpers/breadcrumb.helper";
import { SortHelper } from "../helpers/sort.helper";
import { TranslatePipe } from "../pipes/translate.pipe";

import { NavigationService } from "./navigation.service";
import { SideMenuService } from "./side-nav-portal.service";

type NAVSECTION = "admin" | "campaigns" | "lastCase" | "reports";

interface NavSection {
    section: NAVSECTION;
    name: string;
    route: string;
    visible: () => boolean;
    elements: NavElement[];
}

interface NavElement {
    icon: string;
    text: string;
    route: string;
    visible: () => boolean;
    active: () => boolean;
}

interface SelectOfficeForm {
    isMainOffice: FormControl<boolean>;
    officeId: FormControl<Guid>;
}

@Component({
    imports: [
        WsCommonImports,
        WsFormsImports,
        MatToolbarModule,
        MatButtonModule,
        MatIconModule,
        MatSidenavModule,
        NgClass,
        ExtendedModule,
        MatMenuModule,
        MatListModule,
        PortalModule,
        MatDividerModule,
        MatCardModule,
        MatAutocompleteModule,
        MatOptionModule,
        MatCheckboxModule,
        MatSelectModule,
        MatSlideToggleModule,
        RouterLink,
        AsyncPipe,
        AsControlPipe,
        TranslatePipe
    ],
    selector: "ws-navigation",
    standalone: true,
    styleUrls: ["./navigation.component.scss"],
    templateUrl: "./navigation.component.html"
})
export class NavigationComponent implements AfterViewInit {
    @ViewChild("drawer") sideNav: MatSidenav;
    @ViewChild("rightSideNav") rightSideNav: MatSidenav;
    @ViewChild("profileButton", { read: ElementRef }) profileButton?: ElementRef;
    @ViewChild("profileCard", { read: ElementRef, static: false }) profileCard?: ElementRef;
    @Input() isLoggingIn = true;

    get hideNav$() {
        return this.navigationService.hideNav$;
    }

    navSections: NavSection[] = [];
    lastCaseId: Guid | undefined;

    navigationHeader?: TranslationKeys[];
    versionNumber = version.version;
    isTest = !environment.production;
    showMenu = false;

    // Roles
    hasFullAccess = signal(false);
    isOfficeAdmin = signal(false);
    isAdmin = signal(false);
    showAdminMenu = computed(() => this.hasFullAccess() || this.isOfficeAdmin());

    // Permissions
    showOrganization = false;
    showInternalReports = false;
    showOffices = false;
    showCategories = false;
    showCampaignTemplates = false;
    showAdTemplates = false;
    showAdTemplateOptions = false;
    showSvgTemplates = false;
    showBilling = false;
    showCampaigns = false;
    showReports = false;
    showMembers = false;
    showDashboard = true;

    isHandSet: Signal<boolean>;
    locale: L10nLocale;

    // Member
    memberId: Guid;
    member: Member;
    memberRole: string;

    // Office
    activeOfficeId: Signal<Guid>;
    mainOfficeId = signal<Guid | undefined>(undefined);
    availableOfficeIds = signal<Guid[]>([]);
    form = new FormGroup<SelectOfficeForm>({
        isMainOffice: new FormControl(false, { nonNullable: true }),
        officeId: new FormControl()
    });
    officeFilter = new FormControl("", { nonNullable: true });
    officeFilterVal: Signal<string>;
    allOffices: Signal<Office[]>;
    filteredOffices = computed(() => {
        return SortHelper.SortBy(
            this.isAdmin() || this.hasFullAccess()
                ? this.allOffices()
                : this.allOffices().filter(o => this.availableOfficeIds().includes(o.id)),
            "name"
        ).filter(this._officeFilterFn(this.officeFilterVal()));
    });

    // Organization
    activeOrgId: Guid;
    organizations: Signal<Organization[]>;

    // Misc
    showProfileCard = signal(false);
    isXs: Signal<boolean>;
    darkMode = new FormControl(false, { nonNullable: true });
    outsideClickSub: Subscription;

    get isDarkMode(): boolean {
        return localStorage.getItem("theme") === "dark-theme";
    }

    constructor(
        overlayContainer: OverlayContainer,
        private ts: TranslationService,
        @Inject(L10N_CONFIG) private tsConfig: L10nConfig,
        public navigationService: NavigationService,
        private router: Router,
        private userService: UserService,
        private orgDataService: OrganizationDataService,
        private breakpointObserver: BreakpointService,
        private caseDataService: CaseDataService,
        private officeDataService: OfficeDataService,
        private toastService: ToastService,
        private authService: AuthService,
        private confirmService: ConfirmActionService,
        public sideNavService: SideMenuService,
        private destroyRef: DestroyRef
    ) {
        this.locale = ts.getLocale();
        this.isHandSet = toSignal(this.breakpointObserver.isHandSet$, { initialValue: false });
        this.isXs = toSignal(this.breakpointObserver.isBp$("XSmall"), { initialValue: false });
        this.officeFilterVal = toSignal(
            this.officeFilter.valueChanges.pipe(
                debounceTime(250),
                map(val => val.toLocaleLowerCase())
            ),
            { initialValue: "" }
        );
        this.organizations = toSignal(
            this.orgDataService.entities$.pipe(map(orgs => SortHelper.SortBy(orgs, "name"))),
            { initialValue: [] }
        );
        this.allOffices = toSignal(this.officeDataService.entities$, { initialValue: [] });
        this.activeOfficeId = toSignal(this.userService.activeOfficeId$, {
            initialValue: "" as unknown as Guid
        });

        effect(() => {
            if (this.allOffices().length && this.activeOfficeId()) {
                let off = this.allOffices().find(o => o.id === this.activeOfficeId());
                this.officeFilter.setValue(off?.name ?? "");
            }
        });

        this.darkMode.valueChanges.pipe(takeUntilDestroyed()).subscribe(res => {
            if (res) {
                overlayContainer.getContainerElement()?.classList.add("dark-theme");
                document.getElementById("wave-app")?.classList.add("dark-theme");
                localStorage.setItem("theme", "dark-theme");
            } else {
                overlayContainer.getContainerElement()?.classList.remove("dark-theme");
                document.getElementById("wave-app")?.classList.remove("dark-theme");
                localStorage.setItem("theme", "light-theme");
            }
        });

        this.initUserSettings();
        this.navSections = this.getNavSections();

        this.getBreadCrumbText();
    }

    ngAfterViewInit(): void {
        if (localStorage.getItem("theme") === "dark-theme") {
            this.darkMode.setValue(true);
        }
        this.sideNavService.setSideNav(this.rightSideNav);
    }

    setLang(lang: "en" | "nb" | "sv") {
        const locale = this.tsConfig.schema.find(l => l.text === lang)?.locale;
        if (!locale) return;
        this.ts.setLang(locale);
        this.locale = locale;
    }

    openMemberEdit() {
        this.orgDataService.editMember(this.memberId, false);
        this.closeProfileCard();
    }

    isNewCampaign(route: string) {
        return route.includes("campaign-object/new");
    }

    toggleDrawer() {
        if (this.navSections[0].section !== "reports") {
            this.sideNav.toggle();
        }
    }

    openSection(section: NAVSECTION) {
        const route = this.navSections.find(n => n.section === section)?.route;
        if (route) {
            this.navigate(route);
        }
        if (section === "reports") {
            this.sideNav.close();
            return;
        }

        if (!this.breakpointObserver.isBp(["XSmall", "Small", "Medium"])) {
            this.sideNav.open();
        }
    }

    navigate(route: string) {
        if (this.breakpointObserver.isBp(["XSmall", "Small", "Medium"])) {
            this.sideNav.close();
        }

        const settings: NavigationExtras = {};
        if (this.router.url.includes(route.replace(".", ""))) {
            settings.queryParamsHandling = "merge";
        }

        this.router.navigate([route], settings);
    }

    navigateToNewCaseCampaign() {
        this.router.navigate([`./campaign-object/case/${this.lastCaseId}`]);
    }

    navigateToOfficeCampaign(trigger: MatMenuTrigger) {
        if (!this.lastCaseId) {
            this.router.navigate([`./campaign-object/office/new`]);
            trigger.closeMenu();
        }
    }

    onOfficeFilterFocus() {
        this.officeFilter.setValue("");
    }

    resetOfficeFilter() {
        let office = this.allOffices().find(o => o.id === this.form.getRawValue().officeId);
        setTimeout(() => this.officeFilter.setValue(office?.name ?? ""), 150);
    }

    officeNameFn = (o: Office | string) => (typeof o === "string" ? o : o ? o.name : "");

    selectOffice(officeId: Guid) {
        this.form.controls.officeId.patchValue(officeId);
    }

    changeActiveOrg(orgId: { value: Guid }) {
        this.userService.userSettings.then(u => {
            if (u.activeOrganizationId === orgId.value) {
                return;
            }
            this.userService.changeActiveOrganization$(orgId.value).subscribe({
                error: e => this.toastService.error(e),
                next: () =>
                    (window.location = window.location.href.split("?")[0] as string & Location)
            });
        });
    }

    toggleProfileCard() {
        if (this.showProfileCard()) {
            this.closeProfileCard();
            return;
        }
        this.showProfileCard.set(true);
        this.outsideClickSub = fromEvent(window, "click")
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(e => {
                if (
                    e.target !== this.profileButton?.nativeElement &&
                    e.target !== this.profileCard?.nativeElement &&
                    // TODO: fix
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
                    !this.profileButton?.nativeElement.contains(e.target) &&
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
                    !this.profileCard?.nativeElement.contains(e.target)
                ) {
                    this.closeProfileCard();
                }
            });
    }

    cropBrokerImg(event: Event) {
        const image = event.target as HTMLImageElement;

        if (this.member.mainImage) {
            const cropData = this.member.mainImage.cropData?.find(
                x => x.ratio === AspectRatioEnum.Ratio1X1
            );
            if (!cropData) {
                return;
            }

            // scale ratio
            const scaleRatio = cropData.height / 40;

            if (image.parentElement) {
                image.style.width = image.naturalWidth / scaleRatio + "px";
                image.style.height = image.naturalHeight / scaleRatio + "px";
                image.style.marginLeft = -cropData.left / scaleRatio + "px";
                image.style.marginTop = -cropData?.top / scaleRatio + "px";
            }
        }
    }

    signOut() {
        this.authService.signOut();
    }

    private initUserSettings() {
        this.userService.userSettings.then(s => {
            this.memberId = s.memberId;
            this.memberRole = getHighestRoleCodeFromList(s.roles.map(role => role.code));
            this.orgDataService.loadAll();
            this.officeDataService.loadAll();
            this.orgDataService.getMember$(this.memberId).then(m => {
                this.member = m;
            });

            this.availableOfficeIds.set(s.activeOffices);
            this.activeOrgId = s.activeOrganizationId;
            this.mainOfficeId.set(s.mainOffice);
            if (this.activeOfficeId()) {
                this.form.patchValue({
                    isMainOffice: s.mainOffice === this.activeOfficeId(),
                    officeId: this.activeOfficeId()
                });
            }
            this.setupForm();

            this.showMenu = true;

            this.hasFullAccess.set(this.userService.hasRole(Roles.Webtop, s));
            this.isOfficeAdmin.set(this.userService.hasRole(Roles.OfficeAdmin, s));
            this.isAdmin.set(this.userService.hasRole(Roles.Admin, s));

            this.showReports = this.userService.hasPermission(Permissions.ReportsRead, s);

            this.showOrganization = this.userService.hasPermission(
                Permissions.OrganizationWrite,
                s
            );
            this.showOffices = this.userService.hasPermission(Permissions.OfficesWrite, s);

            this.showCampaignTemplates = this.userService.hasPermission(
                Permissions.CampaignTemplatesWrite,
                s
            );
            this.showCategories = this.userService.hasPermission(Permissions.CategoriesWrite, s);
            this.showAdTemplates = this.userService.hasPermission(Permissions.AdTemplatesWrite, s);
            this.showAdTemplateOptions = this.userService.hasRole(Roles.Webtop, s);
            this.showSvgTemplates = this.userService.hasPermission(
                Permissions.SvgTemplatesWrite,
                s
            );
            this.showBilling = this.userService.hasRole(Roles.Webtop, s);
            this.showMembers = this.userService.hasRole(Roles.Webtop, s);
            this.showCampaigns = this.userService.hasPermission(Permissions.CasesRead, s);
            this.showInternalReports = this.userService.hasRole(Roles.Webtop, s);
        });
    }

    private routeActive(route: string) {
        return this.router.url.endsWith(route);
    }

    private routeContains(route: string) {
        return this.router.url.includes(route);
    }

    private getNavSections = (): NavSection[] => [
        {
            elements: [
                {
                    active: () => this.routeContains("/campaign-object/office/dashboard"),
                    icon: "dashboard",
                    route: "./campaign-object/office/dashboard",
                    text: "navigation.campaigns.dashboard",
                    visible: () => this.showDashboard
                },
                {
                    active: () => this.routeContains("/campaign-object/office/campaigns"),
                    icon: "work",
                    route: "./campaign-object/office/campaigns",
                    text: "navigation.campaigns.campaigns",
                    visible: () => true
                }
            ],
            name: "navigation.campaigns.campaigns",
            route: "./campaigns",
            section: "campaigns",
            visible: () => true
        },
        {
            elements: [
                {
                    active: () => this.routeActive("/reports"),
                    icon: "analytics",
                    route: "./reports",
                    text: "menu.reports",
                    visible: () => this.showReports
                },
                {
                    active: () => this.routeActive("/internal-reports"),
                    icon: "analytics",
                    route: "./internal-reports",
                    text: "menu.internal-reports",
                    visible: () => this.showInternalReports
                }
            ],
            name: "",
            route: "./reports",
            section: "reports",
            visible: () => this.showReports
        },
        {
            elements: [
                {
                    active: () => this.routeContains("admin/organization"),
                    icon: "business",
                    route: "./admin/organization",
                    text: "navigation.admin.organization",
                    visible: () => this.showOrganization
                },
                {
                    active: () => this.routeContains("admin/offices"),
                    icon: "business_center",
                    route: "./admin/offices",
                    text: "navigation.admin.offices",
                    visible: () => this.showOffices
                },
                {
                    active: () => this.routeContains("admin/office-groups"),
                    icon: "cases",
                    route: "./admin/office-groups",
                    text: "navigation.admin.office-groups",
                    visible: () => this.showOffices
                },
                // {
                //     icon: "people",
                //     text: "menu.members",
                //     route: "./admin/members",
                //     visible: () => this.showMembers,
                //     active: () => this.routeContains("admin/member")
                // },
                {
                    active: () => this.routeContains("admin/categories"),
                    icon: "category",
                    route: "./admin/categories",
                    text: "navigation.admin.categories",
                    visible: () => this.showCategories
                },
                {
                    active: () => this.routeContains("admin/campaign-templates"),
                    icon: "local_mall",
                    route: "./admin/campaign-templates",
                    text: "navigation.admin.campaign_templates",
                    visible: () => this.showCampaignTemplates
                },
                {
                    active: () => this.routeContains("admin/ad-templates"),
                    icon: "perm_media",
                    route: "./admin/ad-templates",
                    text: "navigation.admin.ad_templates",
                    visible: () => this.showAdTemplates
                },
                {
                    active: () => this.routeContains("admin/svg-templates"),
                    icon: "upload_file",
                    route: "./admin/svg-templates",
                    text: "navigation.admin.svg_templates",
                    visible: () => this.showAdTemplateOptions
                },
                {
                    active: () => this.routeContains("admin/ad-template-options"),
                    icon: "tune",
                    route: "./admin/ad-template-options",
                    text: "navigation.admin.ad_template_options",
                    visible: () => this.showAdTemplateOptions
                },
                {
                    active: () => this.routeContains("admin/core-webhooks"),
                    icon: "subscriptions",
                    route: "./admin/core-webhooks",
                    text: "navigation.admin.core-webhooks",
                    visible: () => this.hasFullAccess()
                },
                {
                    active: () => this.routeContains("admin/wm-webhooks"),
                    icon: "subscriptions",
                    route: "./admin/wm-webhooks",
                    text: "navigation.admin.wm-webhooks",
                    visible: () => this.hasFullAccess()
                },
                {
                    active: () => this.routeContains("admin/spring-import"),
                    icon: "subscriptions",
                    route: "admin/spring-import",
                    text: "navigation.admin.spring-import",
                    visible: () => this.hasFullAccess()
                },
                {
                    active: () => this.routeContains("admin/media-library"),
                    icon: "movie",
                    route: "./admin/media-library",
                    text: "navigation.admin.media",
                    visible: () => false
                },
                {
                    active: () => this.routeActive("economy/billing"),
                    icon: "payment",
                    route: "./economy/billing",
                    text: "menu.billing_basis",
                    visible: () => false
                },
                {
                    active: () => this.routeActive("admin/billing"),
                    icon: "payment",
                    route: "./admin/billing",
                    text: "navigation.admin.billingV2",
                    visible: () => this.showBilling
                }
            ],
            name: "navigation.admin.admin",
            route: "./admin",
            section: "admin",
            visible: () => this.showAdminMenu()
        }
    ];

    private setupForm() {
        this.updateMainOfficeDisabledState();

        this.userService.activeOfficeId$
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                distinctUntilChanged(),
                filter(x => !!x)
            )
            .subscribe(id => {
                const off = this.allOffices().find(o => o.id === id);
                if (off) {
                    this.officeFilter.setValue(off.name, { emitEvent: false });
                    this.form.controls.officeId.setValue(off.id, { emitEvent: false });
                }
            });

        this.form.controls.officeId.valueChanges
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                switchMap(v => {
                    this.form.controls.isMainOffice.setValue(
                        this.form.getRawValue().officeId === this.mainOfficeId(),
                        { emitEvent: false }
                    );

                    this.updateMainOfficeDisabledState();

                    return this.officeDataService.canDeactivate$(this.confirmService).pipe(
                        filter(y => y),
                        map(() => v)
                    );
                })
            )
            .subscribe(id => {
                let off = this.allOffices().find(o => o.id === id);
                this.userService.setActiveOfficeId(id);
                this.officeFilter.setValue(off?.name ?? "");
            });

        this.form.controls.isMainOffice.valueChanges
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                switchMap(mo => {
                    if (mo) {
                        return this.userService.setMainOffice(
                            this.form.getRawValue().officeId,
                            this.memberId
                        );
                    } else {
                        return EMPTY;
                    }
                })
            )
            .subscribe({
                error: e => this.toastService.error(e),
                next: id => {
                    if (id) {
                        this.mainOfficeId.set(id.mainOffice);
                    }
                    this.updateMainOfficeDisabledState();
                    this.toastService.success();
                }
            });
    }

    private _officeFilterFn(
        filterVal: string = ""
    ): (value: Office, index: number, array: Office[]) => boolean {
        return (value: Office) =>
            !filterVal || value?.name?.toLowerCase().includes(filterVal.toLowerCase());
    }

    private getBreadCrumbText() {
        this.navigationService.lastCaseId$.subscribe(x => (this.lastCaseId = x));

        const streetName$ = this.navigationService.lastCaseId$.pipe(
            switchMap(id => (id ? this.caseDataService.getCaseStreet$(id) : of(undefined)))
        );
        const officeName$ = this.userService.activeOfficeId$.pipe(
            distinctUntilChanged(),
            takeUntilDestroyed(this.destroyRef),
            filter(id => !!id),
            switchMap(id => {
                return this.officeDataService.getOfficeName$(id as Guid);
            })
        );

        const navigationEndEvent$ = this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            map(event => event as NavigationEnd)
        );

        combineLatest([officeName$, navigationEndEvent$, streetName$]).subscribe(params => {
            const url = params[1].urlAfterRedirects || params[1].url;
            const res = BreadCrumbHelper.updateNavHeader(url, params[0], params[2]) || "";

            this.navigationHeader = res.split("#") as TranslationKeys[];
        });
    }

    private closeProfileCard() {
        this.showProfileCard.set(false);
        this.outsideClickSub.unsubscribe();
    }

    private updateMainOfficeDisabledState() {
        if (this.form.getRawValue().isMainOffice) {
            this.form.controls.isMainOffice.disable({ emitEvent: false });
        } else {
            this.form.controls.isMainOffice.enable({ emitEvent: false });
        }
    }
}
