export class BreadCrumbHelper {
    static updateNavHeader(route: string, activeOfficeName?: string, caseStreetName?: string) {
        if (route === "/" || route.includes("/campaign-object/office/dashboard")) {
            // Dashboard
            return activeOfficeName + "#" + "navigation.breadcrumb.dashboard";
        } else if (route.includes("/campaign-object/office/campaigns/")) {
            // Office campaign
            return activeOfficeName + "#" + "navigation.breadcrumb.office_campaign";
        } else if (route.includes("/campaign-object/office/campaigns")) {
            // Campaign list
            return activeOfficeName + "#" + "navigation.breadcrumb.campaign_list";
        } else if (route.includes("/reports")) {
            // Reports
            return "navigation.breadcrumb.reports";
        } else if (route.includes("/admin/organization")) {
            // Org settings
            return "navigation.breadcrumb.org_settings";
        } else if (route.includes("/admin/offices")) {
            // Office settings
            return activeOfficeName + "#" + "navigation.breadcrumb.office_settings";
        } else if (route.includes("/admin/office-groups")) {
            // Office groups
            return "navigation.admin.office-groups";
        } else if (route.includes("/admin/categories")) {
            // Categories
            return "navigation.breadcrumb.categories";
        } else if (route.includes("/admin/campaign-templates")) {
            // Campaign Templates
            return "navigation.breadcrumb.campaign_templates";
        } else if (route.includes("/admin/ad-templates")) {
            // Ad templates
            return "navigation.breadcrumb.ad_templates";
        } else if (route.includes("/admin/svg-templates")) {
            // SVG templates
            return "navigation.breadcrumb.svg_templates";
        } else if (route.includes("/admin/ad-template-options")) {
            // Ad template options
            return "navigation.breadcrumb.ad_template_option";
        } else if (route.includes("/admin/media-library")) {
            // Media library
            return "navigation.breadcrumb.media_library";
        } else if (route.includes("/economy/billing")) {
            // Billing
            return "navigation.breadcrumb.billing";
        } else if (route.includes("/campaign-object/case/campaigns")) {
            // Object campaign
            return caseStreetName + "#" + "navigation.breadcrumb.object_campaign";
        } else if (route.includes("/campaign-object/case/") && !route.includes("/campaigns/")) {
            // Object
            return caseStreetName;
        } else if (route.includes("/campaign-object/office/new")) {
            // New office campaign
            return activeOfficeName + "#" + "navigation.breadcrumb.new_office_campaign";
        } else if (route.includes("/admin/member/")) {
            // Member
            return "";
        } else if (route.includes("/admin/spring-import")) {
            // Spring statuses
            return "Spring import";
        } else if (route.includes("/admin/wm-webhooks")) {
            // Wm webhooks
            return "navigation.admin.wm-webhooks";
        } else if (route.includes("/admin/core-webhooks")) {
            // Core webhooks
            return "navigation.admin.core-webhooks";
        } else {
            return "";
        }
    }
}
