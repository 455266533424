import { enableProdMode } from "@angular/core";
import { bootstrapApplication } from "@angular/platform-browser";
import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import * as Sentry from "@sentry/angular";
import moment, { fn } from "moment";

import { environment, version } from "@vre-wave/infrastructure";

import { AppComponent } from "./app/app.component";
import { appConfig } from "./app/app.config";

import "@angular/common/locales/global/sv";
import "@angular/common/locales/global/nb";

Sentry.init({
    dsn: "https://a5137621d899a54826ada67ebb55ddee@o4507299756965888.ingest.de.sentry.io/4507300448305232",
    enabled: environment.production || environment.test,
    environment: environment.production
        ? "production"
        : environment.test
          ? "staging"
          : "development",
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    release: version.version,
    // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0,
    // Session Replay
    replaysSessionSampleRate: 0.1,
    //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", environment.api], // Performance Monitoring
    tracesSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

if (environment.production || environment.test) {
    datadogLogs.init({
        clientToken: "pubb49e0bcd323ae2c4b70382759fc75bde",
        env: environment.production ? "production" : environment.test ? "staging" : "development",
        forwardErrorsToLogs: false,
        service: "wave",
        sessionSampleRate: 100,
        site: "datadoghq.eu",
        version: version.version
    });

    datadogRum.init({
        allowedTracingUrls: [environment.api],
        applicationId: "3dbce993-e33a-4fb1-9154-d65c50e15f20",
        clientToken: "pub24410828797a4250826cf091a6f40b9a",
        defaultPrivacyLevel: "mask-user-input",
        enableExperimentalFeatures: ["feature_flags"],
        env: environment.production ? "production" : environment.test ? "staging" : "development",
        service: "wave",
        sessionReplaySampleRate: 20,
        sessionSampleRate: 100,
        site: "datadoghq.eu",
        trackLongTasks: true,
        trackResources: true,
        trackUserInteractions: true,
        // Specify a version number to identify the deployed version of your application in Datadog
        version: version.version
    });

    //datadogRum.startSessionReplayRecording();
}

if (environment.production || environment.test) {
    enableProdMode();
}

Date.prototype.toJSON = function () {
    return moment(this).format();
};

fn.toJSON = function () {
    return this.toISOString(true);
};

bootstrapApplication(AppComponent, appConfig)
    // eslint-disable-next-line no-console
    .catch(err => console.error(err));
