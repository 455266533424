import {
    APP_INITIALIZER,
    ApplicationConfig,
    ErrorHandler,
    importProvidersFrom,
    LOCALE_ID
} from "@angular/core";
import { provideAnimations } from "@angular/platform-browser/animations";
import { provideRouter, Router } from "@angular/router";
import { FlexLayoutModule } from "@ngbracket/ngx-layout";
import * as Sentry from "@sentry/angular";

import { environment, provideInfrastructure } from "@vre-wave/infrastructure";

import { APP_ROUTES } from "./app.routing";

export const appConfig: ApplicationConfig = {
    providers: [
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                logErrors: !environment.production,
                showDialog: false
            })
        },
        {
            deps: [Router],
            provide: Sentry.TraceService
        },
        {
            deps: [Sentry.TraceService],
            multi: true,
            provide: APP_INITIALIZER,
            useFactory: () => () => {}
        },
        provideInfrastructure(),
        importProvidersFrom(FlexLayoutModule),
        {
            provide: LOCALE_ID,
            useValue: localStorage.getItem("locale")?.includes(`"sv"`)
                ? "sv"
                : localStorage.getItem("locale")?.includes(`"nb"`)
                  ? "nb"
                  : "en"
        },
        provideAnimations(),
        provideRouter(APP_ROUTES)
    ]
};
