@if (!isPublic) {
    <ws-navigation wsIntercom [isLoggingIn]="(isloggingIn$ | async)!">
        <div fxFill fxLayout="column" style="overflow-x: hidden; overflow-y: auto">
            <div fxFlex fxLayout="column" id="app-content">
                @if ((isloggingIn$ | async) && !isErrorPage) {
                    <ws-loading-bar text="loading.app"></ws-loading-bar>
                }
                <div fxFlex>
                    <ng-container [ngTemplateOutlet]="router"></ng-container>
                </div>
            </div>
        </div>
    </ws-navigation>
}
@if (isPublic) {
    <div fxFill fxLayout="column" style="overflow-x: hidden; overflow-y: auto" id="wave-public">
        <div fxFlex fxLayout="column">
            <ng-container [ngTemplateOutlet]="router"></ng-container>
        </div>
        <footer
            class="appFooter"
            fxLayout="row"
            fxLayoutGap="20px"
            fxLayoutAlign="center center"
            fxLayout.xs="column"
            fxLayoutGap.xs="0"
            fxFlex="40px"
        >
            <a routerLink="/terms" l10nTranslate>tos.terms-link</a>
            <span fxHide.xs>|</span>
            <a href="https://visma.no/eiendom/eiendomsmegling/">&copy; Visma Real Estate AS 2022</a>
            <span fxHide.xs>|</span>
            <mat-slide-toggle color="primary" l10nTranslate [formControl]="darkMode">dark</mat-slide-toggle>
            <span>
                <mat-select
                    style="width: 90px"
                    [placeholder]="'select_lang' | translate: locale.language"
                    (selectionChange)="setLang($event)"
                >
                    <mat-option value="nb">Norsk</mat-option>
                    <mat-option value="sv">Svenska</mat-option>
                    <mat-option value="en">English</mat-option>
                </mat-select>
            </span>
        </footer>
    </div>
}
<ng-template #router><router-outlet></router-outlet></ng-template>
